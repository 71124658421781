<template>
  <div class="site">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item>基础设置</el-breadcrumb-item>
      <el-breadcrumb-item>邮件设置</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-form ref="formRef" :model="form" label-width="120px">
        <el-form-item label="SMTP服务器">
          <el-input v-model="form.servers"></el-input>
        </el-form-item>
        <el-form-item label="SMTP端口">
          <el-input v-model="form.port"></el-input>
        </el-form-item>
        <el-form-item label="SMTP用户名">
          <el-input v-model="form.username"></el-input>
        </el-form-item>
        <el-form-item label="SMTP密码">
          <el-input v-model="form.password"></el-input>
        </el-form-item>
        <el-form-item label="发件人邮箱">
          <el-input v-model="form.sendmail"></el-input>
        </el-form-item>
        <el-form-item label="发件人姓名">
          <el-input v-model="form.sendname"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">立即创建</el-button>
          <el-button>取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Mail',
  data () {
    return {
      form: {
        servers: '',
        port: '',
        username: '',
        password: '',
        sendmail: '',
        sendname: ''
      }
    }
  },
  created () {
    this.getInfo()
  },
  methods: {
    // 获取信息
    async getInfo () {
      const { data: res } = await this.$http.get('mail')
      if (res.status === 200) {
        this.form.servers = res.data.servers
        this.form.port = res.data.port
        this.form.username = res.data.username
        this.form.password = res.data.password
        this.form.sendmail = res.data.sendmail
        this.form.sendname = res.data.sendname
      } else {
        this.$message.error(res.msg)
      }
    },
    // 提交表单
    async onSubmit () {
      const { data: res } = await this.$http.post('mail', this.form)
      if (res.status === 200) {
        this.$message.success(res.msg)
        this.$refs.formRef.resetFields()
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>

<style scoped>

</style>
